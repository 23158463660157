export const environment = {
  release: false,
  production: false,
  defaultLanguage: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en",
  recoveryPasswordUrl:
    "https://ssuasadmindev.shiftelearning.com/password-recovery",
  apiURL:"https://ssuasadmindev.shiftelearning.com/api", // "http://localhost:5001/api",//
  // in seconds
  idleTimeout: 840,
  idleTimeToLogout: 20,
};
