import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./components/auth/guards/auth.guard";

const routes: Routes = [
  {
    path: "tabs",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./components/auth/auth.module").then((m) => m.AuthPageModule),
  },
  {
    path: "eula",
    loadChildren: () =>
      import("./components/eula/eula.module").then((m) => m.EulaPageModule),
  },
  {
    path: "**",
    redirectTo: "",
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
