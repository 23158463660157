import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { LoginResponse, User, TFAResponse } from "../types/auth";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;

  constructor(private _http: HttpClient) {
    //
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(username: string, password: string): Observable<LoginResponse> {
    return this._http
      .post<LoginResponse>(`${environment.apiURL}/login`, {
        username: username,
        password: password,
      })
      .pipe(
        tap(this.setLocalSession.bind(this)),
        catchError(this.handleError.bind(this))
      );
  }

  logout() {
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("2FAPass");
    this.currentUserSubject.next(null);
  }

  isLoggedIn() {
    return (
      Date.now() < parseInt(localStorage.getItem("expiresAt")) &&
      localStorage.getItem("2FAPass")
    );
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  setLocalSession(response: LoginResponse) {
    if (!response.tfa || !response.tfa.secret)
      throw new Error("2FA is not ready");

    let expiretAt =
        Date.parse(response.expirationDate) + response.sessionKeepAliveTimeout,
      user = {
        ...response.user,
        sessionKeepAliveTimeout: response.sessionKeepAliveTimeout,
        expirationDate: response.expirationDate,
        token: response.token,
      } as User;

    localStorage.setItem("expiresAt", expiretAt.toString());
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  verify2FA(authCode: string): Observable<TFAResponse> {
    const url = `${environment.apiURL}/users/${this.currentUserValue.id}/2fa-verify`;
    return this._http
      .post<TFAResponse>(url, { authCode })
      .pipe(
        tap(this.set2FA.bind(this)),
        catchError(this.handleError.bind(this))
      );
  }

  set2FA(response: TFAResponse) {
    if (!response.success) throw new Error(response.data);

    localStorage.setItem("2FAPass", "true");
  }

  send2FAByEmail() {
    const url = `${environment.apiURL}/users/${this.currentUserValue.id}/send-2fa-by-mail`;
    return this._http.get<TFAResponse>(url).pipe(
      tap((response: TFAResponse) => {
        if (!response.success) throw new Error(response.data);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  handleError(reason: HttpErrorResponse) {
    return throwError(reason);
  }

  getToken() {
    return this.currentUserValue?.token;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentUserServerList(): { name: string }[] {
    return this.currentUserSubject.value.serverAccessLevel
      .filter((server) => server.level === 10)
      .map((server) => ({
        name: server.server,
      }))
      .sort((a, b) => {
        return a.name.toUpperCase().trim() < b.name.toUpperCase().trim()
          ? -1
          : a.name.toUpperCase().trim() > b.name.toUpperCase().trim()
          ? 1
          : 0;
      });
  }
}
