import { AfterViewInit, Component, Renderer2 } from "@angular/core";
import { AlertController } from "@ionic/angular";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "../environments/environment";
import { LoaderService } from "./shared/services/loader.service";
import { IdleService } from "./services/idle.service";
import { AuthService } from "./components/auth/service/auth.service";
import { User } from "./components/auth/types/auth";
import { Router } from "@angular/router";
import { Subscription, interval } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements AfterViewInit {
  onTimeoutSub: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    private idleService: IdleService,
    private authService: AuthService,
    private alertController: AlertController,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("cordova")) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
      //
      let language = localStorage.getItem("language");
      language = language ? language : environment.defaultLanguage;
      this.translate.setDefaultLang(language);
      this.translate.use(language);
      //
      this.authService.currentUser.subscribe((user: User) => {
        user ? this.startWatchingUser() : this.idleService.stopWatching();
      });
    });
  }

  ngAfterViewInit() {
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      status
        ? this.renderer.addClass(document.body, "cursor-loader")
        : this.renderer.removeClass(document.body, "cursor-loader");
    });
  }

  startWatchingUser() {
    if (this.onTimeoutSub) this.onTimeoutSub.unsubscribe();

    this.idleService.startWatching();
    this.onTimeoutSub = this.idleService
      .onTimeout()
      .subscribe(async () => await this.showLogoutAlert());
  }

  async showLogoutAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant("alert.alert"),
      message: this.translate.instant("idle.message", {
        countdown: environment.idleTimeToLogout,
      }),
      buttons: [
        {
          text: this.translate.instant("idle.keepLoggedIn"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            this.idleService.startWatching();
            if (intervalSub) intervalSub.unsubscribe();
          },
        },
        {
          text: this.translate.instant("tabs.logout"),
          handler: () => {
            this.idleService.stopWatching();
            if (intervalSub) intervalSub.unsubscribe();
            this.logout();
          },
        },
      ],
    });

    await alert.present();

    const intervalSub = interval(1000).subscribe((seconds) => {
      if (seconds === +environment.idleTimeToLogout - 1) {
        intervalSub.unsubscribe();
        alert.dismiss();
        this.logout();
        return;
      }

      alert.message = this.translate.instant("idle.message", {
        countdown: +environment.idleTimeToLogout - (seconds + 1),
      });
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate([""]);
  }
}
