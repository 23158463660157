import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.auth.logout();
          if (this.router.url !== "/") {
            location.reload();
          }

          return throwError(
            err.status === 401
              ? this.translate.instant("auth.credentials")
              : this.translate.instant("error.unexpected")
          );
        }

        return throwError(err.error.message || err.statusText);
      })
    );
  }
}
